import React from 'react'
import {btn, desktop} from "../layout/index.module.css";
import * as styles from './index.module.css'
import Links from "../links";
import Markdown from 'react-markdown'


const IndexHeader = ({snippet}) => {
    const handleClick = e => {
        e.preventDefault();
        document.getElementById("subscribeFrm").scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    return (
    <div className={styles.indexHeader}>
        <h1>Welcome to SoET Live</h1>
        {snippet.node &&
            <>
            <Markdown>{snippet.node.content}</Markdown>
            <div className={styles.links}>
                <Links links={snippet.node.subheaderLinks}/>
            </div>
            </>
        }
        <a href="#subscribeFrm" onClick={handleClick} className={btn}><span className={desktop}>Stay updated. </span>Subscribe
            to our Newsletter</a>
        <hr/>
    </div>
    )
}

export default IndexHeader
