import React from 'react'
import Icon from './twitter-icon.svg'
import * as styles from './index.module.css'
import Share from './base'


export const ShareTwitter = ({
    text,
    url,
}) => {
    const queryParams = {
        text,
        url,
        hashtags: `europeantech,soet`
    };

    const queryString = new URLSearchParams(queryParams).toString()

    return (
        <Share
            className={styles.shareIcon}
            url={`https://twitter.com/intent/tweet?${queryString}`}
        >
            <Icon />
        </Share>
    );
};

export default ShareTwitter
