import React from 'react'
import { useLocation } from '@reach/router';
import * as styles from './index.module.css'
import ShareCopyLink from './copy-link'
import ShareTwitter from './twitter'
import ShareLinkedIn from './linked-in'

const ShareBlock = ({url, title, text, className}) => {
    const location = useLocation()

    const fullyQualifiedUrl = url || location.href
    return (
        <div className={styles.container}>
            <div className={className || styles.shareBlock}>
                <ShareCopyLink url={fullyQualifiedUrl} />
                <ShareTwitter url={fullyQualifiedUrl} text={text} />
                <ShareLinkedIn url={fullyQualifiedUrl} title={title} text={text} />
            </div>
            <hr/>
        </div>
    )
}

export default ShareBlock
