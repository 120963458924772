import React from 'react'
import * as styles from "../links/index.module.css";
import External from "../../global-styles/icons/external.svg";
import Internal from "../../global-styles/icons/internal.svg";

const Links = ({links}) => (
    <>
        {links &&
            <ul className={styles.links}>
            {
                links.map(l => {
                    let Arrow = External
                    if (l.url.includes('stateofeuropeantech.com')) {
                        Arrow = Internal
                    }
                    return <li>
                        <a href={l.url} key={l.id}>
                            <Arrow alt={l.title}/>
                            {l.title}
                        </a>
                    </li>
                })
            }
            </ul>
        }
    </>
)

export default Links