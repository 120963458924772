import React from 'react'
import Icon from './linked-in-icon.svg'
import * as styles from './index.module.css'
import Share from './base'


export const ShareLinkedIn = ({
    title,
    text,
    url,
}) => {
    const queryParams = {
        url,
        sourceUrl: url,
        title: title,
        summary: text || ``,
        source: `Atomico`
      }

    const queryString = new URLSearchParams(queryParams).toString()

    return (
        <Share
            className={styles.shareIcon}
            url={`https://www.linkedin.com/shareArticle?${queryString}`}
        >
            <Icon />
        </Share>
    );
};

export default ShareLinkedIn
