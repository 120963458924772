import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import * as styles from "./index.module.css";
import {mobile, notMobile} from "../layout/index.module.css";
import Logo from "./logo.svg";
import LogoSmall from "./logosmall.svg";

export const Nav = () => {
    const {
        site: {
            siteMetadata: { reportSite, title }
        }
    } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    reportSite
                }
            }
        }
    `);

    const isThreadsSelected = (props) => {
      const currentLocation = props.pathname
      if (currentLocation.includes("threads") || currentLocation.includes("/t/") || currentLocation === '/') {
        return { className: styles.selected }
      }
    }

    const [scrolledPastHeader, setScrolledPastHeader] = React.useState(0);

    React.useEffect(() => {
       document.addEventListener("scroll", () => {
         const scrollCheck = window.scrollY > 0; // Mobile header is 56, we only change for that.
          if (scrollCheck !== scrolledPastHeader) {
            setScrolledPastHeader(scrollCheck);
          }
       })
    })

    let bannerStyle = styles.banner;
    let bannerAfterScroll = styles.bannerAfterScrollHidden;
    if(scrolledPastHeader){
        bannerStyle = styles.bannerHiddenMobile;
        bannerAfterScroll = styles.bannerAfterScrollShown;
    }

    return (
      <div className={styles.container}>
        <div className={bannerStyle}>
          <Link to={"/"}  className={styles.logo}>
              <Logo alt={title} className={notMobile}/>
              <LogoSmall alt={title} className={mobile}/>
          </Link>
        </div>
        <div className={bannerAfterScroll}>
          <Link to={"/"}  className={styles.logo}>
              <LogoSmall alt={title} />
          </Link>
        </div>
        <ul className={styles.links}>
            <li>
                <Link getProps={({ location }) => isThreadsSelected(location)} to={"/"}>Threads</Link>
            </li>
            <li>
                <Link activeClassName={styles.selected} partiallyActive={true} to={"/media"}>Media</Link>
            </li>
        </ul>
      </div>
    )
}

export default Nav;
