import React from "react";

import * as styles from "./index.module.css";
import Header from "../header";
import Nav from "../nav";
import Footer from "../footer";
import {Full} from "../grid";
import DownWhite from '../../global-styles/icons/downwhite.svg'
import SubscribeForm from "../subscribe-form";
import DownloadButton from "../download-button";
import BackButton from "../back-button";

export const ShowMore = ({next}) => (
    <Full className={styles.showMoreContainer}>
        <button className={styles.showMore} onClick={next}>
            <DownWhite alt={"Show More"}/>
            Show More
        </button>
    </Full>
)

export const LoadMoreThreads = ({next}) => (
    <Full className={styles.loadMoreThreadsContainer}>
        <button className={styles.loadMoreThreads} onClick={next}>
            <DownWhite alt="Load More Threads"/>
            Load More Threads
        </button>
    </Full>
)

export const Layout = ({ children }) => (
  <>
    <BackButton/>
    <DownloadButton/>
    <Header/>
    <Nav/>
    {children}
    <SubscribeForm />
    <Footer/>
  </>
)
export default Layout;
