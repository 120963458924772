module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-f9b700170c/0/cache/gatsby-plugin-manifest-npm-4.3.0-fa4e211a1e-bdc3fb1a6a.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The State of European Tech Live","short_name":"State of European Tech Live","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb2dcfae2aee409e841985ef4bce616e"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-e97f4972e2/0/cache/gatsby-plugin-google-analytics-npm-4.10.0-9f00be37db-18154d8c16.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-36046017-2","anonymize":false,"head":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-505f32bd01/0/cache/gatsby-plugin-image-npm-2.0.0-0d54fb6283-c182f004d0.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-netlify-cms-virtual-c4a47e6674/0/cache/gatsby-plugin-netlify-cms-npm-5.14.0-af2ef95d0b-b4c6d616e4.zip/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/production.js","manualInit":true,"enableIdentityWidget":true},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Barlow:400,600","Fraunces:400,600"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
