import React from 'react'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

const Image = ({file, alt, className}) => {
    if (file.childImageSharp === null || file.childImageSharp === undefined) {
        return <img className={className || ''} src={file.publicURL} alt={alt} />
    } else {
        return <GatsbyImage className={className || ''} image={getImage(file)} alt={alt} />
    }
}

export default Image
