import React from 'react'
import TagBubble from '../tag-bubble'
import * as styles from './index.module.css'
import {desktop, notDesktop} from "../../components/layout/index.module.css";


const TagListing = ({tags, urlRoot, currentTag, title="Themes"}) => {
    const show = `Show ${title} +`;
    const hide = `Hide ${title} -`;

    const [showStyle, setShowStyle] = React.useState(desktop); // leverage desktop style for toggle as same.
    const [showAction, setShowAction] = React.useState(show);

    const toggle = () => {
        setShowStyle(showStyle === desktop ? notDesktop : desktop);
        setShowAction(showAction === hide ? show : hide);
    }

    return (
        <div className={styles.container}>
            <div className={desktop + ' ' + styles.title}>{title}</div>
            <button className={notDesktop + ' ' + styles.btn} onClick={toggle} href={'#'}>
                <h7>{showAction}</h7>
            </button>
            <div className={showStyle}>
                {tags.map(t => <TagBubble tag={t} urlRoot={urlRoot} currentTag={currentTag} key={t.id}/>)}
            </div>
        </div>
    )
}

export default TagListing
