import React from 'react'
import Markdown from 'react-markdown'
import {Helmet} from "react-helmet";
import {Tweet} from 'react-twitter-widgets'
import PageHeader from "../page-header";
import * as styles from "../thread/index.module.css";
import * as loadingStyles from "../thread/loading.module.css";
import Grid, {Full} from "../grid";
import Ellipse from "./ellipse.svg";
import Date from "../dates";
import {RelatedListings} from "../index-listing";
import {LoadMoreThreads} from "../layout";
import Pin from "../../global-styles/icons/pin.svg";
import Links from "../links";
import Image from "../image";


const LoadingBlock = () => (
    <div className={loadingStyles.postLoadCard}>
        <div className={loadingStyles.bodyWrap}>
            <div className={loadingStyles.loadingAnim}/>
            <div className={loadingStyles.loadingAnim}/>
            <div className={loadingStyles.loadingAnim}/>
            <div className={loadingStyles.loadingAnim}/>
        </div>
        <div className={loadingStyles.footerWrap}/>
    </div>
)

const TweetBlock = ({block, setBlockLoading}) => {
    return <Tweet tweetId={block.tweetId} onLoad={() => setBlockLoading(false)}/>
}

const Block = ({block, lastItem, showMoreBtn, next}) => {
    const FoundBlock = {
        TweetBlock: TweetBlock
    }[block.__typename]

    const [blockLoading, setBlockLoading] = React.useState(true);

    if (FoundBlock) {
        return <Full className={styles.threadBlockWrapper}>
            <div className={styles.threadBlock}>
                <div className={styles.threadBlockDateContainer}>
                    <Date date={block.date} extraStyle={styles.threadBlockDate}/>
                    <Ellipse className={styles.theadBlockEllipse} alt={block.date}/>
                </div>
                <div className={blockLoading ? styles.threadBlockItemLoading : styles.threadBlockItem}>
                    {blockLoading && <LoadingBlock/>}
                    <FoundBlock block={block} setBlockLoading={setBlockLoading}/>
                    {(lastItem && showMoreBtn) &&
                        <LoadMoreThreads next={next} />
                    }
                </div>
            </div>
        </Full>
    } else {
        return null
    }
}

const Insight = ({thread}) => (
    <Full className={styles.threadBlockWrapper}>
        <div className={styles.insight}>
            <div className={styles.insightHeader}><Pin alt={"Overview"}/>Overview</div>
            <Markdown>{thread.insight}</Markdown>
            <Links links={thread.insightLinks}/>
        </div>
    </Full>
)

const ThreadBlocks = ({thread}) => {
    const perShow = 5;
    const [visibleBlocks, setVisibleBlocks] = React.useState(perShow);
    const next = () => {
        setVisibleBlocks( visibleBlocks + perShow);
    }
    const showMore = visibleBlocks < thread.blocks.length

    return <>
        <Grid className={styles.threadGrid}>
            {thread.insight && <Insight thread={thread}/>}
            {thread.blocks.slice(0, visibleBlocks).map((b, i, arr) => (
                <Block block={b} key={b.id} lastItem={i + 1 === arr.length} showMoreBtn={showMore} next={next} />
            ))}
        </Grid>
    </>
}

const Thread = ({thread, related}) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={thread.title}/>
                <meta name="twitter:description" content={thread.insight}/>
                <meta name="twitter:image" content={thread.featuredImage.publicURL}/>
                <meta property="og:title" content={thread.title}/>
                <meta property="og:image" content={thread.featuredImage.publicURL}/>
                <meta property="og:description" content={thread.insight}/>
                <meta property="og:url" content={url}/>
            </Helmet>
            <Image className={styles.heroImage} file={thread.heroImage} alt={thread.title} />
            <Grid>
                <Full className={styles.container}>
                    <PageHeader title={thread.title}/>
                    <ThreadBlocks thread={thread}/>
                    <RelatedListings obj={thread} related={related} />
                </Full>
            </Grid>
        </>
    )
}

export const CmsThreadPreview = ({entry, getAsset, widgetsFor}) => {
    let data = entry.toJS().data;
    const thread = {
        id: data['id'],
        title: data['title'],
        blocks: widgetsFor('blocks').map(b => ({
            id: b.getIn(['data', 'id']),
            code: b.getIn(['data', 'code']),
            __typename: b.getIn(['data', 'type']),
        })),
    }

    return <Thread thread={thread}/>
}

export default Thread
