// extracted by mini-css-extract-plugin
export var btn = "index-module--btn--3E64M";
export var showMoreContainer = "index-module--showMoreContainer--1nZII";
export var loadMoreThreadsContainer = "index-module--loadMoreThreadsContainer--1xj_S index-module--showMoreContainer--1nZII";
export var showMore = "index-module--showMore--1Z60T index-module--btn--3E64M";
export var loadMoreThreads = "index-module--loadMoreThreads--2F1l8 index-module--btn--3E64M";
export var mobile = "index-module--mobile--3y91C";
export var desktop = "index-module--desktop--2MczS";
export var notMobile = "index-module--notMobile--2FCcq";
export var notTablet = "index-module--notTablet--2OReB";
export var notDesktop = "index-module--notDesktop--2ZTzU";
export var textClamp = "index-module--textClamp--h8pDe";