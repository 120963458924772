// extracted by mini-css-extract-plugin
export var recentNewsTitle = "index-module--recentNewsTitle--2-cTi";
export var listingContainer = "index-module--listingContainer--14b5D";
export var listingLarge = "index-module--listingLarge--Tl_IN";
export var listingSmall = "index-module--listingSmall--3vsDt";
export var imageContainer = "index-module--imageContainer--kj7Hj";
export var imageRibbon = "index-module--imageRibbon--3FTzq";
export var imageRibbonNew = "index-module--imageRibbonNew--1ftPm index-module--imageRibbon--3FTzq";
export var imageRibbonUpdated = "index-module--imageRibbonUpdated--31ZuN index-module--imageRibbon--3FTzq";
export var statusLabel = "index-module--statusLabel--17Oh4";
export var newLabel = "index-module--newLabel--Q2pHx index-module--statusLabel--17Oh4";
export var updatedLabel = "index-module--updatedLabel--3cfsm index-module--statusLabel--17Oh4";
export var statusBullet = "index-module--statusBullet--39U70";
export var listingImage = "index-module--listingImage--2lbg6";
export var listingLink = "index-module--listingLink--3xsze";
export var listingTagsTop = "index-module--listingTagsTop--ob0BD";
export var listingTagsBottom = "index-module--listingTagsBottom--1ZQYc";
export var related = "index-module--related--ayHTy";
export var contentRelated = "index-module--contentRelated--VPVmR";
export var relatedListingsContainer = "index-module--relatedListingsContainer--2WhO2";
export var relatedListings = "index-module--relatedListings--3KbMo";