import React from 'react'
import { graphql } from 'gatsby'
import TagListing from '../components/tag-listing'
import IndexListing, {RecentNews} from '../components/index-listing'
import Grid from "../components/grid";
import IndexHeader from "../components/index-header";

export const objectsQuery = graphql`
    query ($tag: String, $objectType: String) {
        allCmsSnippet(
            filter: {slug: {eq:"index"}}
            limit: 1
        ) {
            edges {
              node {
                content
                subheaderLinks {
                  id
                  title
                  url
                }
              }
            }
          }
        allCmsTag {
            nodes {
                ...TagLinkData
            }
        }
        allIndexObject(
            filter: {objectType: {glob: $objectType} tags: {elemMatch: {id: {eq: $tag }}}}
            sort: {fields: updatedDate, order: DESC}
        ) {
            nodes {
                ...IndexListingData
            }
        }
        allCmsNews (
          limit: 3
        )
        {
           nodes {
            ...CmsNewsData
          }
        }
    }
`

const Listing = ({data, pageContext}) => {

    return (
        <>
            <Grid>
                <IndexHeader snippet={data.allCmsSnippet.edges[0]}/>
                <TagListing tags={data.allCmsTag.nodes} currentTag={pageContext.tag} urlRoot={`${pageContext.indexRoot}t/`} />
                <IndexListing objects={data.allIndexObject.nodes} urlRoot={pageContext.indexRoot} />
                <RecentNews news={data.allCmsNews} />
            </Grid>
        </>
    )
}

export default Listing
