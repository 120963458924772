import React from 'react'
import {graphql, Link} from 'gatsby'
import * as styles from './index.module.css'

export const TagLinkData = graphql`
    fragment TagLinkData on CmsTag {
        id
        title
        slug
    }
`

const TagBubble = ({ tag, urlRoot, currentTag, alt = false }) => {
    let style = styles.tagBubble;
    if(alt){
        style = styles.tagBubbleAlt;
    }

    let to = urlRoot + tag.slug
    if (currentTag === tag.id) {
        style = styles.tagBubbleSelected
        to = '/';
    }

    return urlRoot !== undefined
        ? <Link className={style} to={to} activeClassName={styles.tagBubbleSelected}>{tag.title}</Link>
        : <span className={style}>{tag.title}</span>
}

export default TagBubble
