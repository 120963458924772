import React from 'react'
import {Link, graphql} from 'gatsby'
import * as styles from './index.module.css'
import {btn, notMobile, textClamp} from "../../components/layout/index.module.css";
import TagBubble from "../tag-bubble";
import Image from "../image";
import InternalAlt from "../../global-styles/icons/internal_alt.svg";
import NewBullet from "../../global-styles/icons/new-bullet.svg";
import UpdatedBullet from "../../global-styles/icons/updated-bullet.svg";
import Grid, {Thin, WideNestedGrid} from "../grid";
import {NewsEntry} from "../news";
import Internal from "../../global-styles/icons/internal.svg";
import {ShowMore} from "../layout";
import Markdown from 'react-markdown'
import dayjs from "dayjs"


export const IndexListingData = graphql`
    fragment IndexListingData on IndexObject {
        id
        title
        createdDate
        updatedDate
        subTitle
        path
        tags {
            id
            title
            slug
        }
        featuredImage {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
            extension
            publicURL
        }
    }
`

export const RelatedListings = ({obj, related}) => {
    return <>
        <hr/>
        <Grid className={styles.relatedListingsContainer}>
            <Thin>
                <h6>RELATED POSTS</h6>
                <ul className={styles.relatedListings}>
                    {obj.tags.map(t => <li key={t.slug}><TagBubble tag={t} urlRoot={'/threads/t/'} alt={true} /></li>)}
                </ul>
            </Thin>
            <WideNestedGrid>
                {related.nodes.map(r => <Thin key={r.id}><IndexListingElement obj={r} urlRoot={'/'} related={true} /></Thin>)}
            </WideNestedGrid>
        </Grid>
    </>
}

export const RecentNews = ({news}) => {
    return <>
        <div className={styles.recentNewsTitle}>
            <hr />
            <h4>Media Coverage</h4>
            <div>
                <Link to={"/media"} className={btn}><Internal alt={"View all Media coverage"}/> View all Media coverage</Link>
            </div>
        </div>
        <div className={styles.listingContainer}>
            {news.nodes.map((e, i) => <NewsEntry entry={e} key={e.id} index={i} recent={true} />)}
        </div>
    </>
}

const IndexListingElement = ({obj, index = null, urlRoot, related = false}) => {
    let style = styles.listingSmall
    let contentStyle = styles.content;

    if (related){
        style = `${style} ${styles.related}`
        contentStyle = styles.contentRelated;
    }

    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const [isNew, setIsNew] = React.useState(false)
    const [isRecentlyUpdated, setIsRecentlyUpdated] = React.useState(false)

    React.useEffect(() => {
        setIsNew(dayjs(obj.createdDate) > oneWeekAgo)
        setIsRecentlyUpdated(dayjs(obj.updatedDate) > oneWeekAgo)
    }, [setIsNew, setIsRecentlyUpdated])

    return <div className={style}>
        <Link to={`${urlRoot}${obj.path}`} className={styles.imageContainer}>
                <Image className={styles.listingImage} file={obj.featuredImage} alt={obj.title} />
                {isNew ?
                    <div className={styles.imageRibbonNew}>NEW THREAD</div>
                :
                isRecentlyUpdated &&
                    <div className={styles.imageRibbonUpdated}>RECENTLY UPDATED</div>
                }
            <div className={styles.listingTagsBottom}>
                {obj.tags.map(t => <TagBubble tag={t} urlRoot={`${urlRoot}t/`} alt={true} key={t.slug}/>)}
            </div>
            <div className={contentStyle}>
                <h6 className={textClamp}>
                    {isNew ?
                        <span className={styles.newLabel}>
                            <NewBullet alt={"New Bullet"} className={styles.statusBullet} />NEW
                        </span>
                    :
                    isRecentlyUpdated &&
                        <span className={styles.updatedLabel}>
                            <UpdatedBullet alt={"Updated Bullet"} className={styles.statusBullet} />UPDATED
                        </span>
                    }
                    {obj.title}
                </h6>
                <Markdown allowElement={(element) => element.tagName === 'p'} className={textClamp}>
                    {obj.subTitle}
                </Markdown>
            </div>
            <InternalAlt alt={obj.title} className={`${styles.listingLink} ${notMobile}`}/>
        </Link>
    </div>
}

const IndexListing = ({objects, urlRoot}) => {
    const perShow = 8;
    const [visibleListings, setVisibleListings] = React.useState(perShow);

    const next = () => {
        setVisibleListings( visibleListings + perShow);
    }

    return (<>
        <div className={styles.listingContainer}>
            {objects.slice(0, visibleListings).map((obj, i) => <IndexListingElement obj={obj} key={obj.id} index={i} urlRoot={urlRoot}/>)}
        </div>
        {visibleListings < objects.length &&
            <ShowMore next={next}/>
        }
        </>)
}

export default IndexListing
