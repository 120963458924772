import React, {useState} from "react";
import * as styles from "./index.module.css";
import unfetch from "unfetch/dist/unfetch.umd.js";

// Global request config
const unReq = ({data, method, url}) => {
    return unfetch(url, {
        method,
        headers: {
            "mode": 'no-cors',
            "Accept": `application/json, text/plain, */*`,
            "Content-Type": `application/json;charset=UTF-8`,
            "x-api-key": process.env.GATSBY_MAILCHIMP_API_KEY
        },
        body: JSON.stringify(data)
    });
};

export const mailchimpRequest = (email) => {
    unReq({
        data: {email},
        method: `post`,
        url: process.env.GATSBY_MAILCHIMP_NEWSLETTER
    })
        .then(res => res)
        .catch(err => {
            // Sometimes these are invalid/duplicate address errors, sometimes other
            // errors. We can't tell the difference for now, so just ignore.
            console.log(`err`, err); // eslint-disable-line no-console
        });
};

export const SubscribeForm = () => {
    const [email, setEmail] = useState("");

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        mailchimpRequest(email);
        setEmail("");
    }

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Let us keep you updated</h3>
            <p className={styles.message}>Get Threads & other SoET updates into your inbox</p>
            <form className={styles.subscribeForm} onSubmit={handleSubmit} id={"subscribeFrm"}>
                <input type="text" value={email} placeholder={"Email Address"} onChange={onEmailChange}/>
                <button type="submit">Subscribe</button>
            </form>
            <p className={styles.optOutText}>You can opt out at anytime</p>
        </div>
    )
}

export default SubscribeForm