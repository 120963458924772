import React from 'react'

import * as styles from "./index.module.css";
import dayjs from "dayjs"

export const Date = ({ date, small=false, extraStyle = ''}) => {
    const styleSize = small ? styles.small : styles.large
    return <div className={`${styleSize} ${extraStyle}`}>
        {dayjs(date).format('DD.MM.YY')}
    </div>
};

export default Date