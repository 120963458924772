import React from 'react'
import { graphql } from 'gatsby'
import News from '../components/news'
import Grid from "../components/grid";

export const newsQuery = graphql`
    query {
      allCmsRegion {
        nodes {
            id
            title
            slug
        }
      }
      allCmsNews(
        sort: {fields: date, order: DESC}
      ) {
        nodes {
          ...CmsNewsData
        }
      }
      cmsNewsMeta {
        content
        subheaderLinks {
            id
            title
            url
        }        
      }
    }
`

const CmsNews = ({ data: { allCmsNews, allCmsRegion, cmsNewsMeta }, pageContext }) => {
    return (
        <Grid>
            <News news={allCmsNews} regions={allCmsRegion} meta={cmsNewsMeta} currentRegion={pageContext.currentRegion} />
        </Grid>
    )
}

export default CmsNews
