import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import * as styles from "./index.module.css";
import {btn, notMobile, mobile, tablet} from "../../components/layout/index.module.css";
import Atomico from "../../global-styles/icons/atomico.svg";
import AtomicoSmall from "../../global-styles/icons/atomicosmall.svg";
import Logo from "./logo.svg";
import LogoSmall from "./logosmall.svg";
import {Link} from "gatsby";
import OrrickLogoDark from "../../global-styles/icons/orrick-dark.svg"
import SlushLogoDark from "../../global-styles/icons/slush-dark.svg"
import SvbLogoDark from "../../global-styles/icons/svb-dark.svg"
import BgLogoDark from "../../global-styles/icons/bg-dark.svg"

export const Header = () => {
    const {
        site: {
            siteMetadata: { reportSite, title }
        }
    } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    reportSite
                    title
                }
            }
        }
    `);

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <AtomicoSmall alt={"Atomico"} className={`${mobile} ${styles.smallAtomico}`}/>
                <Link to={reportSite}  className={styles.logo}>
                    <Logo alt={title} className={notMobile}/>
                    <LogoSmall alt={title} className={mobile}/>
                </Link>
            </div>

            <div className={styles.partners}>
              <a
                href="https://atomico.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Atomico
                  className={styles.partnerIcon}
                  alt="Atomico"
                />
              </a>
              <div className={styles.separator} />
              <a
                href="https://www.slush.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SlushLogoDark
                  className={styles.partnerIcon}
                  alt="Slush"
                />
              </a>
              <div className={styles.separator} />
              <a
                href="https://www.orrick.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <OrrickLogoDark
                  className={styles.colorChangingIcon}
                  alt="Orrick"
                />
              </a>
              <div className={styles.separator} />
              <a
                href="https://www.svb.com/uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvbLogoDark
                  className={styles.partnerIcon}
                  alt="SVB"
                />
              </a>
              <div className={styles.separator} />
              <a
                href="https://www.bailliegifford.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BgLogoDark
                  className={styles.partnerIcon}
                  alt="Baillie Gifford"
                />
              </a>
            </div>
            <div className={styles.smallPartners}>
                <Atomico alt={"Atomico"} className={notMobile}/>
            </div>
        </div>
    )
}

export default Header
