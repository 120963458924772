import React from 'react'
import {graphql} from 'gatsby'
import Thread from '../components/thread'

export const threadQuery = graphql`
  query ($id: String!, $tags: [String]!) {
  cmsThread(id: {eq: $id}) {
    title
    insight
    createdDate
    updatedDate
    featuredImage {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
      extension
      publicURL
    }
    heroImage {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
      extension
      publicURL
    }
    blocks {
      ... on TweetBlock {
        id
        tweetId
        date
      }
      __typename
    }
    insightLinks {
        id
        title
        url
    }
    tags {
      ...TagLinkData
    }
  }
  allIndexObject(
    limit: 3
    filter: {tags: {elemMatch: {slug: {in: $tags}}}}
    ) {
       nodes {
            ...IndexListingData
        }
    }
}

`

const CmsThread = ({data: {cmsThread, allIndexObject}}) => {
    return <Thread thread={cmsThread} related={allIndexObject}/>
}

export default CmsThread
