import React from 'react'
import Markdown from 'react-markdown'

import * as styles from './index.module.css'
import ShareBlock from '../share'


const PageHeader = ({title, summary, share = true, alt=false}) => (
    <div className={alt ? styles.pageHeaderAlt : styles.pageHeader}>
        <h1>{title}</h1>
        <Markdown>{summary}</Markdown>
        {share && <ShareBlock className={styles.headerShareBlock} text={summary || title} title={title} />}
    </div>
)

export default PageHeader
