import React from 'react'
import * as styles from './index.module.css'
import {Link} from 'gatsby'
import {desktop, notDesktop} from "../../components/layout/index.module.css";


const RegionListing = ({regions, currentRegion, selectedRegions, handleSelectRegion}) => {
    const title = "Filter by Region";
    const show = `Show ${title} +`;
    const hide = `Hide ${title} -`;

    const [showStyle, setShowStyle] = React.useState(desktop); // leverage desktop style for toggle as same.
    const [showAction, setShowAction] = React.useState(show);

    const toggle = () => {
        setShowStyle(showStyle === desktop ? notDesktop : desktop);
        setShowAction(showAction === hide ? show : hide);
    }

    return (
        <div className={styles.container}>
            <div className={desktop + ' ' + styles.title}>{title}</div>
            <button className={notDesktop + ' ' + styles.btn} onClick={toggle} href={'#'}>
                <h7>{showAction}</h7>
            </button>
            <div className={showStyle}>
                {regions.map(r => currentRegion === r.slug ?
                    <Link className={styles.regionBubbleSelected} to={`/media`}>{r.title}</Link>
                    : selectedRegions.length > 0 ?
                        <a href={`/media/r/${r.slug}`} onClick={(e) => handleSelectRegion(e, r.slug)} className={selectedRegions.includes(r.slug) ? styles.regionBubbleSelected : styles.regionBubble}>{r.title}</a>
                        :
                        <Link className={styles.regionBubble} to={`/media/r/${r.slug}`} activeClassName={styles.regionBubbleSelected}>{r.title}</Link>
                )}
            </div>
        </div>
    )
}

export default RegionListing
