import React from "react";
import Icon from "./icon.svg";
import * as styles from "./index.module.css";
import {graphql, useStaticQuery} from "gatsby";

const BackButton = () => {
    const {
        site: {
            siteMetadata: { reportSite }
        }
    } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    reportSite
                }
            }
        }
    `);

  return (
      <>
        <a className={styles.backButton} href={reportSite}>
          <Icon className={styles.icon} />
          <span>Go back to<br/>the Report</span>
        </a>
      </>
  );
};

export default BackButton
