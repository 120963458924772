import React from "react";
import * as styles from "./index.module.css";

export const Thin = ({ className, children, ...others }) => (
  <div className={`${className || ``} ${styles.thin}`} {...others}>
    {children}
  </div>
);

export const Half = ({ className, children, ...others }) => (
  <div className={`${className || ``} ${styles.half}`} {...others}>
    {children}
  </div>
);

export const Wide = ({ className, children, ...others }) => (
  <div className={`${className || ``} ${styles.wide}`} {...others}>
    {children}
  </div>
);

export const WideNestedGrid = ({ className, children, ...others }) => (
  <div className={`${className || ``} ${styles.wideNestedGrid}`} {...others}>
    {children}
  </div>
);

export const Full = ({ className, children, ...others }) => (
  <div className={`${className || ``} ${styles.full}`} {...others}>
    {children}
  </div>
);

const Grid = ({ className, children }) => (
  <div className={`${className || ``} ${styles.grid}`}>{children}</div>
);

export default Grid;
