// extracted by mini-css-extract-plugin
export var heroImage = "index-module--heroImage--2U-pL";
export var threadGrid = "index-module--threadGrid--FS0Al";
export var threadBlockWrapper = "index-module--threadBlockWrapper--1l-lg";
export var threadBlock = "index-module--threadBlock--1tTYJ";
export var threadBlockDateContainer = "index-module--threadBlockDateContainer--eRW_H";
export var threadBlockItemLoading = "index-module--threadBlockItemLoading--5zXrg";
export var threadBlockItem = "index-module--threadBlockItem--10sbI";
export var threadBlockDate = "index-module--threadBlockDate--1VOTQ";
export var theadBlockEllipse = "index-module--theadBlockEllipse--1TSzE";
export var relatedGrid = "index-module--relatedGrid--39EsP";
export var insight = "index-module--insight--3SYsD";
export var insightHeader = "index-module--insightHeader--rpo3L";