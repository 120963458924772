import React from 'react'
import Grid, {Full} from "../grid";
import * as styles from "../news/index.module.css";
import {notMobile, notTablet} from "../../components/layout/index.module.css";
import Image from "../image";
import ExternalAlt from '../../global-styles/icons/external_alt.svg'
import Date from "../dates";
import PageHeader from "../page-header";
import {graphql} from "gatsby";
import {ShowMore} from "../layout";
import Links from "../links";
import RegionListing from "../region-listing";

export const CmsNewsData = graphql`
    fragment CmsNewsData on CmsNews {
        id
        date
        url
        title
        tags {
          title
        }
        region {
          title
          slug
        }
        featuredImage {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
            extension
            publicURL
        }
    }
`

export const NewsEntry = ({ entry, index, recent=false }) => {
    let style = styles.newsEntry;
    let contentStyle = styles.content;
    if (recent){
        style = styles.newsEntryRecent
        contentStyle = styles.contentRecent;
    }
    if(index === 2){
        // when on tablet, we show 2 not 3 as per others.
        style = `${style} ${notTablet}`;
    }

    return <div className={style}>
        <a href={entry.url} target="_blank" rel="noopener noreferrer">
        <Image className={styles.featuredImage} file={entry.featuredImage} alt={entry.title} />
        <div className={contentStyle}>
            <Date date={entry.date} small={true}/>
            <h6>{entry.title}</h6>
        </div>
        <ExternalAlt alt={"View news"} className={notMobile} />
        </a>
    </div>
};

const News = ({ news, regions, meta, currentRegion }) => {
    const perShow = 18;
    const [visibleNews, setVisibleNews] = React.useState(perShow);
    const [selectedRegions, setSelectedRegions] = React.useState([]);

    const next = () => {
        setVisibleNews( visibleNews + perShow);
    }

    const handleSelectRegion = (e, slug) => {
        e.preventDefault();
        setVisibleNews(perShow);
        if (selectedRegions.indexOf(slug) > -1) {
            setSelectedRegions(selectedRegions.filter(r => r !== slug))
        } else {
            setSelectedRegions([
              ...selectedRegions,
              slug,
            ]);
        }
      };

    if (currentRegion && selectedRegions.indexOf(currentRegion) === -1) {
        setSelectedRegions([...selectedRegions, currentRegion])
    }

    let allNews = news.nodes;
    if (selectedRegions.length > 0) {
        allNews = allNews.filter(n => selectedRegions.includes(n.region.slug));
    }

    return <>
        <Full>
            <PageHeader
                title={'Media'}
                summary={meta.content}
                share={false}
                alt={true}
            />
            <RegionListing regions={regions.nodes} currentRegion={currentRegion} selectedRegions={selectedRegions} handleSelectRegion={handleSelectRegion} />
            <div className={styles.links}>
                <Links links={meta.subheaderLinks}/>
            </div>
        </Full>
        <Full>
            <Grid className={styles.container}>
                {allNews.slice(0, visibleNews).map((entry, idx) => (
                    <React.Fragment key={entry.id}>
                        <NewsEntry entry={entry}/>
                    </React.Fragment>
                ))}
            </Grid>
        </Full>

        {visibleNews < allNews.length &&
            <ShowMore next={next}/>
        }
    </>
};

export const CmsNewsPreview = ({ entry, getAsset, widgetsFor }) => {
    const data = entry.toJS().data;
    const news = {
        id: data.id,
        title: data.title,
    }

    return <News news={news} />
}

export default News
