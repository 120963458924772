import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Share from './base'
import Icon from './copy-link-icon.svg'
import * as styles from './index.module.css'

const ShareCopyLink = ({url}) => {
    const [copied, setCopied] = React.useState(false)

    return (
        <>
            <CopyToClipboard
                text={url}
                onCopy={() => {
                    setCopied(true)
                    setTimeout(() => setCopied(false), 2000)
                }}
            >
                <Share url={url} onClick={e => e.preventDefault()}>
                    <div className={`${styles.shareNotification} ${(copied ? styles.show : styles.hide)}`}>
                        Link copied!
                    </div>
                    <Icon />
                </Share>
            </CopyToClipboard>
        </>
    )
}

export default ShareCopyLink