import React from "react";
import Icon from "./icon.svg";
import * as styles from "./index.module.css";
import {graphql, useStaticQuery} from "gatsby";

const DownloadButton = () => {
  const {
      site: {
          siteMetadata: { reportSite, title }
    }
  } = useStaticQuery(graphql`
    {
        site {
            siteMetadata {
                reportSite
            }
        }
    }
  `);
  const url = reportSite + 'chapter/executive-summary/?downloadReport=1';

  return (
    <a className={styles.downloadButton} href={url}>
      <Icon className={styles.icon} />
      <span>Download Report</span>
    </a>
  );
};

export default DownloadButton
