import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import * as styles from "./index.module.css";
import Atomico from "../../global-styles/icons/atomico.svg";

export const Footer = () => {
    const {
        site: {
            siteMetadata: { legalUrl, privacyUrl }
        }
    } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    legalUrl
                    privacyUrl
                }
            }
        }
    `);

    return (
        <div className={styles.container}>
            <div>
                <Atomico alt={"Atomico"}/>
                &copy; Atomico {new Date().getFullYear()}
            </div>
            <div className={styles.footerLinks}>
                <a href={legalUrl}>Legal</a>
                    |
                <a href={privacyUrl}>Privacy</a>
            </div>
        </div>
    )
}

export default Footer
