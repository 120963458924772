import React from 'react'
import * as styles from './index.module.css'

const Share = ({url, children, onClick}) => {
    return (
        <a
            className={styles.share}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick || (() => {})}
        >
            <div className={styles.inner}>{children}</div>
        </a>
    );
};

export default Share
